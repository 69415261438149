import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Portfolios `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tr/"
            }}>{`All-Stars Total Return`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-stocks/"
            }}>{`All-Stars Stocks`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-lev/"
            }}>{`All-Stars Leveraged`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: meta-portfolio`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking stocks, bonds, commodities`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $7,500`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`All-Stars Monthly`}</em>{` aims to continually beat the balanced 60/40 benchmark while reducing portfolio maintenance to a minimum. The meta-portfolio achieves its objective by combining two proprietary momentum strategies with a bond rotation strategy, resulting in additional diversification benefits. `}<em parentName="p">{`All-Stars Monthly`}</em>{` rebalances once per month, leading to low maintenance requirements and appealing to investors with a busy lifestyle or smaller accounts.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, All-Stars Monthly: A low-volatility meta-strategy",
                "title": "TuringTrader.com, All-Stars Monthly: A low-volatility meta-strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/all-stars-portfolios/"
            }}>{`All-Stars Portfolios`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`All-Stars Monthly`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`divide capital into three equal-sized tranches`}</li>
        <li parentName="ul">{`allocate one tranche each to TuringTrader's `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`Round-Robin`}</a>{`, `}<a parentName="li" {...{
            "href": "/portfolios/tt-straight-four/"
          }}>{`Straight Four`}</a>{`, and `}<a parentName="li" {...{
            "href": "/portfolios/tt-buoy/"
          }}>{`Buoy`}</a></li>
        <li parentName="ul">{`rebalance between tranches once per month`}</li>
      </ul>
      <p>{`Curious investors will note that we are using a slight variant of `}<em parentName="p">{`Round Robin`}</em>{` which replaces the leveraged treasury ETFs with their unleveraged equivalents.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`All-Stars Monthly`}</em>{` aims to combine the benefits of a healthy diversification across multiple asset classes with those of tactical asset allocation. Two of the three tranches, `}<em parentName="p">{`Round-Robin`}</em>{` and `}<em parentName="p">{`Straight Four`}</em>{`, focus on growth through investing in the stock market. To add stability, the third tranche, `}<em parentName="p">{`Buoy`}</em>{`, focuses on investments in debt instruments. Each tranche tilts its allocation to the current market environment, continually shifting the exact allocations between asset classes.`}</p>
      <p><em parentName="p">{`All-Stars Monthly`}</em>{` combines momentum and mean-variance-optimization portfolios in a single strategy. This diversification across trading styles improves portfolio decisions' quality. As a result, the meta-strategy achieves higher risk-adjusted returns than the individual components, and can cope with a wider range of market scenarios.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`All-Stars Monthly`}</em>{` handily beats the 60/40 benchmark in most years. During market downturns, the portfolio makes further gains over this benchmark. Over the full economic cycle, `}<em parentName="p">{`All-Stars Monthly`}</em>{` leads over the S&P 500 by a wide margin.`}</p>
      <p>{`The chart above shows how `}<em parentName="p">{`All-Stars Monthly`}</em>{` continually expands its lead over the 60/40 benchmark while mostly avoiding deep downturns.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`All-Stars Monthly`}</em>{` trades frequently and regularly triggers taxable events. Consequently, the strategy works best in tax-deferred accounts. However, due to its continuous and robust growth, it may also provide value in taxable accounts.`}</p>
      <p>{`The strategy's monthly rebalancing schedule not only reduces maintenance efforts but also addresses potential issues with T+2 accounts.`}</p>
      <p>{`To allow for proper position sizing, `}<em parentName="p">{`All-Stars Monthly`}</em>{` requires a minimum investment of $7,500.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, December 2020`}</a>{`: Initial release, combining `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`Round Robin`}</a>{`, `}<a parentName="li" {...{
            "href": "/portfolios/tt-straight-four/"
          }}>{`Straight Four`}</a>{`, and `}<a parentName="li" {...{
            "href": "/portfolios/tt-bonds-not/"
          }}>{`Bonds-NOT`}</a>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, April 2022`}</a>{`: Replaced `}<a parentName="li" {...{
            "href": "/portfolios/tt-bonds-not/"
          }}>{`Bonds-NOT`}</a>{` with `}<a parentName="li" {...{
            "href": "/portfolios/tt-buoy/"
          }}>{`Buoy`}</a>{` for better performance in environments of rising rates.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=3"
          }}>{`v3, October 2022`}</a>{`: Upgraded `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin"
          }}>{`Round Robin`}</a>{` to v2 for improved performance in bear markets.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=4"
          }}>{`v4, January 2023`}</a>{`: Upgraded `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin"
          }}>{`Round Robin`}</a>{` to v3 and tweaked `}<a parentName="li" {...{
            "href": "/portfolios/tt-buoy/"
          }}>{`Buoy`}</a>{` to limit trades to month-end only.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      